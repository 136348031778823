import { getTopicPosition } from "../api/connection";
import { defaultConverter } from "../utils/converter";
import { Sensor } from "../models";
import { closeSseconnection } from "../api/connection";
import { isTokenExpired } from "./authFunctions";
import { useNavigate } from 'react-router-dom';

export const getSelectedDataPosition = async (data, setMapPosition) => {
    try {
        const topicPosition = await getTopicPosition(data);
        // console.log('topicPosition:', topicPosition)

        const { x, y, cluster_sensors } = topicPosition.data;
        const [lat, lng] = defaultConverter.convertToLatLng(+x, +y);
        setMapPosition([lat, lng]);
        return cluster_sensors
    } catch (err) {
        console.log(err);
    }
};

export const createSensors = (cluster_sensors, setSensor) => {
    // console.log('cluster_sensors:', cluster_sensors)
    if (cluster_sensors.length) {
        for (const sensor of cluster_sensors) {
            const {
                id,
                location,
                orientation,
                range,
                foi,
                azimuth,
                elevation,
                height,
            } = sensor;
            // console.log(orientation, azimuth)
            const [lat, lng] = defaultConverter.convertToLatLng(
                +location[0],
                +location[1],
            );
            const newSensor = new Sensor({
                id: id,
                coordinates: [lat, lng, +height],
                range: range,
                angleOfInterest: foi,
                azimuth: orientation,
                elevation: elevation,
                azimuthOffset: azimuth,
            });
            setSensor(newSensor);
            // console.log(newSensor)
        }
    } else console.log("error creating sensors");
};

export const handleOnMessage = (eventSource, setReceivedMessages) => {
    console.log('in handleONMessage')
    if (eventSource) {
        console.log('Listening to event source messages')
        eventSource.onmessage = function (event) {
            // console.log('event', event)
            const message = JSON.parse(event.data);
            const payloadValue = JSON.parse(message.payload);
            const newMessage = { 'topic': message.topic, 'payload': payloadValue }
            
            // console.log('message:', message);
            // console.log('newMessage:', newMessage)

            setReceivedMessages(prevMessages => {
                const filteredMessages = prevMessages.filter(message => message.payload.object_id !== newMessage.payload.object_id);
                return [newMessage, ...filteredMessages];
            });

        };
    } else {
        console.error("EventSource is not ready");
    }
};

export const handleCloseSseConnection = async (eventSource) => {
   if (eventSource) {
        if (typeof eventSource.close === "function") {
            eventSource.close(); // Closes the connection to the EventSource
            console.log("EventSource connection closed");
        };
        try {
            await closeSseconnection(); // Optional: Informs the server to clean up
        } catch (error) {
            console.error("Error during EventSource close cleanup:", error);
        };
   };
};

export const redirectToConnectionPage = (eventSource, deleteAllMarkers, setAllSensors, setReceivedMessages, setSubscribeTopic) => {
    handleCloseSseConnection(eventSource);
    localStorage.setItem("page", "connectionPage");
    localStorage.setItem("showSettings", "no");
    deleteAllMarkers();
    setAllSensors([]);
    setReceivedMessages([]);
    setSubscribeTopic('');
};

export const redirectToLogin = (eventSource, setSubscribeTopic, setReceivedMessages) => {
    handleCloseSseConnection(eventSource);
    localStorage.clear();
    localStorage.setItem("page", "notAuthorized");
    setSubscribeTopic('');
    setReceivedMessages([]);
};