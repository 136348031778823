import React, { useEffect } from 'react';
import { useRecoilState } from "recoil";
import { Box, Typography } from '@mui/material';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { useNavigate } from 'react-router-dom';
import { useMarkerTools } from "../hooks/useMarkerTools";
import { allSensorsAtom, subscribeTopicAtom, eventSourceAtom, receivedMessagesAtom } from "../recoil/atom";
import { isTokenExpired } from "../functions/authFunctions";
import { redirectToLogin, redirectToConnectionPage } from "../functions/connectionFunctions";

const InformationPage = () => {
    const navigate = useNavigate();
    const { deleteAllMarkers } = useMarkerTools();
    const [subscribeTopic, setSubscribeTopic] = useRecoilState(subscribeTopicAtom);
    const [allSensors, setAllSensors] = useRecoilState(allSensorsAtom);
    const [eventSource, setEventSource] = useRecoilState(eventSourceAtom);
    const [receivedMessages, setReceivedMessages] = useRecoilState(receivedMessagesAtom);

    const user = localStorage.getItem("user");
    const role = user ? JSON.parse(user).role : null;

    useEffect(() => {
        const token = localStorage.getItem("token");
        console.log("isTokenExpired:", isTokenExpired(token))
        if (isTokenExpired(token)) {
            console.log("redirect to login page");
            redirectToLogin(eventSource, setSubscribeTopic, setReceivedMessages);
            navigate("/login");
        };
        if (!isTokenExpired(token) && subscribeTopic === '') {
            if (role === "admin") {
                console.log("redirect to connection page");
                redirectToConnectionPage(eventSource, deleteAllMarkers, setAllSensors, setReceivedMessages, setSubscribeTopic);
                navigate("/connection");
            } else {
                console.log("redirect to login page");
                redirectToLogin(eventSource, setSubscribeTopic, setReceivedMessages);
                navigate("/login");
            };
        };
    }, []);

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                height: "100vh",
                marginTop: "10vh"
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: "2vh",
                    border: "1px solid grey",
                    borderRadius: "8px",
                    width: "80%",
                }}>
                <Box sx={{ display: "flex", alignItems: "center", width: "85%", gap: 5, margin: "2vh" }}>
                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", width: "70px" }}>
                        <WarningRoundedIcon sx={{ color: "#ff3d00" }} />
                        <Typography sx={{ fontSize: "12px" }}>Opened</Typography>
                    </Box>
                    <Typography>
                        New event where a device is being detected.
                    </Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center", width: "85%", gap: 5, margin: "2vh" }}>
                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", width: "70px" }}>
                        <WarningRoundedIcon sx={{ color: "#ff9100" }} />
                        <Typography sx={{ fontSize: "12px" }}>Update</Typography>
                    </Box>
                    <Typography>
                        Event that has been updated due to the device moving.
                    </Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center", width: "85%", gap: 5, margin: "2vh" }}>
                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", width: "70px" }}>
                        <WarningRoundedIcon sx={{ color: "#ffc400" }} />
                        <Typography sx={{ fontSize: "12px" }}>Keep Alive</Typography>
                    </Box>
                    <Typography>
                        Event that has been opened but the device is not activly moving.
                    </Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center", width: "85%", gap: 5, margin: "2vh" }}>
                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", width: "70px" }}>
                        <WarningRoundedIcon sx={{ color: "#4caf50" }} />
                        <Typography sx={{ fontSize: "12px" }}>Closed</Typography>
                    </Box>
                    <Typography>
                        Event no longer detected.
                    </Typography>
                </Box>
            </Box>
        </Box>
    )
}

export default InformationPage;
