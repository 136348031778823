import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import GuardFLogo from '../../images/GuardFLogo.png';
import './Nav.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import SettingsIcon from '@mui/icons-material/Settings';
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Menu, MenuItem, Box, Tooltip, IconButton, Switch } from '@mui/material';
import { handleCloseSseConnection } from "../../functions/connectionFunctions"

import { useRecoilState } from "recoil";
import { subscribeTopicAtom, receivedMessagesAtom, showSensorConfigAtom, eventSourceAtom } from "../../recoil/atom";

const Nav = () => {
    const page = localStorage.getItem("page");
    const showSettings = localStorage.getItem("showSettings");
    const user = localStorage.getItem("user");
    const role = user ? JSON.parse(user).role : null;
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const [subscribeTopic, setSubscribeTopic] = useRecoilState(subscribeTopicAtom);
    const [receivedMessages, setReceivedMessages] = useRecoilState(receivedMessagesAtom);
    const [showSensorConfig, setShowSensorConfig] = useRecoilState(showSensorConfigAtom);
    const [eventSource, setEventSource] = useRecoilState(eventSourceAtom);

    const navigate = useNavigate();

    const handleLogoutUser = () => {
        handleCloseSseConnection(eventSource); 
        localStorage.clear();
        console.log(localStorage)
        localStorage.setItem("page", "notAuthorized");
        setSubscribeTopic('');
        setReceivedMessages([]);
        navigate("/login");  
    };

    const handleBackButton = () => {
        localStorage.setItem("page", "alertsPage");
        localStorage.setItem("showSettings", "yes");
        navigate(-1);
    };

    const handleAdmin = () => {
        localStorage.setItem("page", "adminUserPage");
        localStorage.setItem("showSettings", "no");
        navigate("/admin/users")
    };

    const handleSettingsClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleSetToggle = () => {
        setShowSensorConfig(!showSensorConfig);
        console.log(showSensorConfig);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDirectToInfo = () => {
        handleClose();
        localStorage.setItem("page", "informationPage");
        navigate("/info");
    };

    // console.log(page)

    // console.log(role)

    // console.log(showSettings)


    return (
        <nav className={page === "notAuthorized" || page === null ? "navbar_not_authorized" : "navbar"}>
            {localStorage.getItem("token") && (
                (localStorage.getItem("page") === "alertsPage" || localStorage.getItem("page") === "connectionPage") && (
                    <div className="navbar_logout_icon">
                        <Tooltip title="Log out">
                            <IconButton
                                className="logOut"
                                variant="contained"
                                onClick={handleLogoutUser}
                            >
                                <FontAwesomeIcon icon={faRightFromBracket} rotation={180} style={{ color: '#ffffff', fontSize: 'medium' }} />
                            </IconButton>
                        </Tooltip>
                    </div>
                ))}
            {localStorage.getItem("token") && (
                (localStorage.getItem("page") === "adminUserPage" || localStorage.getItem("page") === "informationPage") && (
                    <div className="navbar_logout_icon">
                        <Tooltip title="Back">
                            <IconButton
                                className="back"
                                variant="contained"
                                onClick={handleBackButton}
                            >
                                <ArrowBackIosIcon
                                    icon={faRightFromBracket}
                                    rotation={180}
                                    style={{ color: '#ffffff', fontSize: 'medium' }}
                                />
                            </IconButton>
                        </Tooltip>
                    </div>
                ))}
            <div className="navbar_image_div">
                <img className="navbar_image" src={GuardFLogo} alt="GuardF logo" />
            </div>
            {localStorage.getItem("token") && (
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center"
                    }}
                >
                    {role === "admin" && (
                        <Tooltip title="Admin">
                            <IconButton
                                className="admin"
                                variant="contained"
                                onClick={handleAdmin}
                            >
                                <AdminPanelSettingsIcon
                                    sx={{ color: "#fff" }}
                                />
                            </IconButton>
                        </Tooltip>
                    )}
                    {localStorage.getItem("showSettings") === "yes" && (
                        <div className="navbar_settings_icon">
                            <Tooltip title="Settings">
                                <IconButton
                                    className="settings"
                                    variant="contained"
                                    onClick={handleSettingsClick}
                                >
                                    <SettingsIcon
                                        sx={{ color: '#ffffff' }}
                                    />
                                </IconButton>
                            </Tooltip>
                            <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}
                            >
                                <MenuItem>
                                    Sensor Configuration
                                    <Switch
                                        checked={showSensorConfig}
                                        onChange={handleSetToggle}
                                    />
                                </MenuItem>
                                {/* <MenuItem>Show Events</MenuItem> */}
                                <MenuItem onClick={handleDirectToInfo}>Information</MenuItem>
                                {/* <MenuItem onClick={handleClose}>History</MenuItem> */}
                            </Menu>
                        </div>
                    )}
                </Box>
            )
            }
        </nav >
    )
}

export default Nav;