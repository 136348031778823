import { useEffect } from "react";
import L from "leaflet";
import { useRecoilValue } from "recoil";
import { receivedMessagesAtom, hoverEventAtom } from "../../../recoil/atom";
import locationImage from '../../../images/location.png';
import locationHoverImage from '../../../images/locationHover.png';
import { defaultConverter } from "../../../utils";


const LiveDataMarker = ({ map }) => {
    const receivedMessages = useRecoilValue(receivedMessagesAtom);
    const hoverEvent = useRecoilValue(hoverEventAtom);
    // Keep track of layers by object_id
    const existingLayers = {};

    useEffect(() => {
        const locationIcon = L.icon({
            iconUrl: locationImage,
            iconSize: [32, 32], // size of the icon
            iconAnchor: [16, 32], // point of the icon which will correspond to marker's location
            popupAnchor: [0, -32] // point from which the popup should open relative to the iconAnchor
        });

        const locationHoverIcon = L.icon({
            iconUrl: locationHoverImage,
            iconSize: [32, 32],
            iconAnchor: [16, 32],
            popupAnchor: [0, -32]
        });

        map.eachLayer(layer => {
            if (layer instanceof L.Marker || layer instanceof L.Polygon || layer instanceof L.Circle) {
                if (layer._isLiveDataLayer) {
                    map.removeLayer(layer); // Remove only live data layers
                };
            };
        });

        receivedMessages.forEach(message => {
            Object.keys(existingLayers).forEach((key) => {
                // Check if the existing layer matches the current message's points
                if (
                    (message.payload.zone === null && existingLayers[key] === JSON.stringify(message.payload.XY)) ||
                    (message.payload.zone && message.payload.zone.type === "polygon" && existingLayers[key] === JSON.stringify(message.payload.zone.data.points)) ||
                    (message.payload.zone && message.payload.zone.type === "circle" && existingLayers[key] === JSON.stringify(message.payload.zone.data.points[0]))
                ) {
                    return; // Skip adding a new layer if one already exists
                }
            });

            let layer;

            if (message.payload.zone === null && message.payload.XY) {
                const X = message.payload.XY[0];
                const Y = message.payload.XY[1];

                if (X && Y) {
                    const position = defaultConverter.convertToLatLng(X, Y);
                    const isHovering = hoverEvent?.XY && JSON.stringify(hoverEvent.XY) === JSON.stringify(message.payload.XY);
                    // Create marker
                    layer = L.marker(position, {
                        icon: isHovering ? locationHoverIcon : locationIcon
                    }).addTo(map);
                    // Store the layer using points
                    if (!existingLayers[JSON.stringify(message.payload.XY)]) {
                        existingLayers[JSON.stringify(message.payload.XY)] = layer;
                    };
                };

            } else if (message.payload.zone && message.payload.zone.type === "polygon") {
                const points = message.payload.zone.data.points;
                const isHovering = hoverEvent?.zone?.data?.points && JSON.stringify(hoverEvent.zone.data.points) === JSON.stringify(points);
                // Convert points to LatLng
                const latLngPoints = points.map(point => defaultConverter.convertToLatLng(point[0], point[1]));
                // Add polygon to the map
                layer = L.polygon(latLngPoints, { 
                    color: isHovering ? '#FF0000' : '#880808'
                }).addTo(map);

                if (!existingLayers[JSON.stringify(points)]) {
                    existingLayers[JSON.stringify(points)] = layer;
                };

            } else if (message.payload.zone && message.payload.zone.type === "circle") {
                console.log('message:', message)
                console.log('hoverEvent:', hoverEvent)
                const centerPoint = message.payload.zone.data.points[0];
                const radius = message.payload.zone.data.radius;
                const centerLatLng = defaultConverter.convertToLatLng(centerPoint[0], centerPoint[1]);

                const isHovering = hoverEvent?.zone?.data?.points[0] && JSON.stringify(hoverEvent.zone.data.points[0]) === JSON.stringify(centerPoint);

                // Add circle to the map
                layer = layer = L.circle(centerLatLng, {
                    radius,
                    color: isHovering ? '#FF0000' : '#880808',
                }).addTo(map);

                if (!existingLayers[JSON.stringify(centerPoint[0])]) {
                    existingLayers[JSON.stringify(centerPoint[0])] = layer;
                };
            };

            if(layer) {
                // Add a custom property to identify the layer as a live data layer
                layer._isLiveDataLayer = true;
            };
        });

    }, [receivedMessages, hoverEvent, map]);


    return null; // Marker is added to the map directly, no need to render anything
};

export default LiveDataMarker;


// CODE FOR HOVE OVER POINT
// Create and bind popup
//     const popupContent = `${message.payload.main}, ${message.payload.sub}`;

//     const popupOptions = {
//         closeButton: false // Disable close button
//     };

//     layer.bindPopup(popupContent, popupOptions);

//     Show popup on mouseover
//     layer.on('mouseover', function (e) {
//         this.openPopup();
//     });

//     Hide popup on mouseout
//     layer.on('mouseout', function (e) {
//         this.closePopup();
//     });